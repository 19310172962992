import { render, staticRenderFns } from "./village.vue?vue&type=template&id=1d4dc674&scoped=true"
import script from "./village.vue?vue&type=script&lang=js"
export * from "./village.vue?vue&type=script&lang=js"
import style0 from "./village.vue?vue&type=style&index=0&id=1d4dc674&prod&scoped=true&lang=less"
import style1 from "./village.vue?vue&type=style&index=1&id=1d4dc674&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d4dc674",
  null
  
)

export default component.exports