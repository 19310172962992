<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addOption">新增</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="villageName" label="乡村名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="introduce" label="乡村介绍" show-overflow-tooltip align="center"></el-table-column>
<!--      <el-table-column prop="imgUrl" label="乡村图片" show-overflow-tooltip align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <img class="imgs" :src="scope.row.img1" alt="">-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="edits(scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="deletes(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--    编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="乡村名称" prop="villageName">
            <el-input v-model="addForm.villageName" placeholder="请输入乡村名称"></el-input>
          </el-form-item>
          <el-form-item label="乡村介绍" prop="introduce">
            <el-input v-model="addForm.introduce" placeholder="请输入乡村介绍"></el-input>
          </el-form-item>
<!--          <el-form-item class="isUpload" label="乡村图片" prop="imgUrl">
            <el-upload
              action="/imgUpdata"
              :on-success="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
              list-type="picture-card"
              :file-list="cImgfileList"
              multiple
            >
              &lt;!&ndash; <img
                v-if="addForm.imgUrl"
                :src="addForm.imgUrl"
                class="businessImg avatar"
              /> &ndash;&gt;
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'sensorManagement',
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      addForm: {},
      dialogVisible: false,
      rules: {
        villageName: [
          {required: true, message: "乡村名称不可为空", trigger: ["blur", "change"]},
        ],
        introduce: [
          {required: false, message: "乡村介绍不可为空", trigger: ["blur", "change"]},
        ],
        /*imgUrl: [
          {required: true, message: "乡村图片不可为空", trigger: ["blur", "change"]},
        ],*/
      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true,
      fileList: [],
      cImgfileList:[],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //请求列表数据
    getList() {
      this.$get("/villageIntroduction/getVillageIntroductions", {
        page: this.page,
        size: this.size,
        villageName: this.searchName,

      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          for(let a = 0; a < this.tableData.length; a++){
              this.$set(this.tableData[a], 'img1', this.tableData[a].imgUrl.split(",")[0])
          }
          //console.log("获取列表", this.tableData)
        }
      });
    },
    // 编辑
    edits(row) {
        //console.log(row)
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.messageBox = true;
    //   this.cImgfileList = [];
    //   let img = [];
    //   img = row.imgUrl.split(",");
    //   //console.log(img)
      this.addForm = JSON.parse(JSON.stringify(row));
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/villageIntroduction/delVillageIntroductionById", {
            id: id,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },
    // 确认提交
    saveForm(formName) {
      // let id = this.ids;
      this.addForm.id = this.ids;
      let url = '/villageIntroduction/addOrUpdateVillageIntroduction';
      if(this.formTitle == '新增'){
        delete this.addForm.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(url, this.addForm
          ).then((res) => {
            if (res.data.state == 'success') {
              if(this.formTitle == '新增'){
                this.$message.success('添加成功')
              }else{
                this.$message.success('修改成功')
              }    
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.cImgfileList = [];
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },
    search() {
      //console.log(11111)
      this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    beforeAvatarUpload2(val) {
      const fileLimitSize = 2;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false
      }

      return true;
    },
    handleAvatarSuccess2(res, file) {
        this.cImgfileList.push(file);
        let data = [];
        for(let a = 0; a < this.cImgfileList.length; a++){
            data.push("https://public.half-half.cn/" + this.cImgfileList[a].response.data.fileName)
        }
        this.addForm.imgUrl = data.join(",")
    //   this.addForm.imgUrl =
    //     "https://public.half-half.cn/" + res.data.fileName;
        //console.log(this.addForm.imgUrl)
    },
    handlePictureCardPreview(file){
        this.dialogVisible = true;
        //console.log(file)
    },
    handleAvatarRemove(file,fileList){
      this.cImgfileList = fileList;
    },

  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}
.isUpload /deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 12vh;
  height: 12vh;
}
.isUpload /deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.isUpload /deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 12vh;
  height: 12vh;
  line-height: 12vh;
  text-align: center;
}
.isUpload /deep/ .el-upload--picture-card{
    width: 12vh;
    height: 12vh;
    line-height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.isUpload /deep/ .el-upload-list__item{
  width: 12vh;
  height: 12vh;
}
.el-upload-list__item-actions{
  width:12vh!important;
}
.el-form-item__content >div{
  display:flex;
}
.isUpload .businessImg {
  width: 12vh;
  height: 12vh;
}
.imgs{
    width: 6vh;
    height: 6vh;
}

</style>


